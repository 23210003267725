<template>
    <div class="d-flex flex-column fill-height">
        <v-col class="pa-0 ma-0 mb-auto">
            <v-row no-gutters>
                <v-col class="col-9-5 d-flex flex-column pt-3">
                    <div>
                        <span class="header-title">{{ Office__title }}</span>
                        <span class="header-subtitle font-weight-bold" style="vertical-align: top" v-if="Office__companyno != null && Office__companyno != '' && Office__companyno != 'NA'">{{ '(' + Office__companyno + ')' }}</span>
                    </div>
                    <span class="header-subtitle pt-1 pb-0">{{ Office__address1  }}</span>
                    <span class="header-subtitle pt-1 pb-0">{{ [Office__address2, Office__postcode, Office__city, Office__state, Office__country].join(', ')  }}</span>
                    <span class="header-subtitle pt-1 pb-0">{{ $t('message.tel') + ': ' + Office__contracttel  }}</span>
                    <span class="header-subtitle pt-1 pb-0">{{ $t('message.email') + ': ' + Office__contractemail + ' ' + $t('message.website') + ': ' + Office__website }}</span>
                </v-col>
                <v-col class="col-2-5 text-right">
                    <img
                        :src="appLogo"
                        class="mb-3 mr-3"
                        width="130px"
                    />
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12" class="text-center text-uppercase border-top-2 border-bottom-2 pa-0 font-weight-bold document-caption">{{ $t('message.contractRequest') }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6" class="d-flex flex-column py-0">
                    <span class="font-weight-bold">{{ $t('message.seller') }}</span>
                    <span style="font-weight: normal" v-if="[1,5].includes(Ncr__salestype_id)">{{ Ncr__office_id != null ? Office__title : null }}</span>
                    <div v-else-if="[2].includes(Ncr__salestype_id)" class="d-flex flex-column">
                        <span style="font-weight: normal">{{ Supplier__title }}</span>
                        <span style="font-weight: normal">{{ Supplier__address1 }}</span>
                        <span style="font-weight: normal">{{ Supplier__address2 }}</span>
                        <span style="font-weight: normal">{{ Supplier__address3 }}</span>
                        <span style="font-weight: normal">{{ Supplier__city + ' ' + (Supplier__postcode != null ? Supplier__postcode : '') }}</span>
                        <span style="font-weight: normal">{{ Supplier__state }}</span>
                        <span style="font-weight: normal">{{ Supplier__country }}</span>
                    </div>
                </v-col>
                <v-col cols="2" class="d-flex flex-column py-0">
                    <span class="font-weight-bold">{{ $t('message.marketing') }}</span>
                    <span>{{ Salescontact__name }}</span>
                </v-col>
                <v-col cols="2" class="d-flex flex-column py-0">
                    <span class="font-weight-bold">{{ $t('message.date') }}</span>
                    <span>{{ Ncr__ncrdate ? formatDate(Ncr__ncrdate) : '' }}</span>
                </v-col>
                <v-col cols="2" class="d-flex flex-column py-0">
                    <span class="font-weight-bold text-right">{{ $t('message.number') }}</span>
                    <span class="text-right">{{ Ncr__title }}</span>
                </v-col>
            </v-row>
            <v-row no-gutters class="pt-1">
                <v-col cols="6" class="d-flex flex-column py-0">
                    <span class="font-weight-bold">{{ $t('message.buyer') }}</span>
                    <span>{{ Customer__title }}</span>
                    <span>{{ Customer__address1 }}</span>
                    <span>{{ Customer__address2 }}</span>
                    <span>{{ Customer__address3 }}</span>
                    <span>{{ Customer__city + ' ' + (Customer__postcode != null ? Customer__postcode : '')}}</span>
                    <span>{{ Customer__state }}</span>
                    <span>{{ Customer__country }}</span>
                    <span v-html="getTaxId()"></span>
                </v-col>
                <v-col cols="6" class="d-flex flex-column py-0">
                    <span class="font-weight-bold">{{ $t(deliveryCaption) }}</span>
                    <span v-html="nl2br(Deliveryaddress__address)"></span>
                </v-col>
            </v-row>
            <v-row no-gutters class="pt-1">
                <v-col cols="7" class="d-flex flex-column py-0">
                    <span class="font-weight-bold">{{ $t('message.termsOfPayment') }}</span>
                    <span>{{ Paymentstatement__title }}</span>
                </v-col>
                <v-col cols="4" class="d-flex flex-column py-0 offset-1">
                    <span class="font-weight-bold text-right">{{ $t('message.shipment') }}</span>
                    <span class="text-right" v-if="Ncr__shippingweek == null">{{ Ncr__etddate ? formatDate(Ncr__etddate) : '' }}</span>
                    <span class="text-right" v-if="Ncr__shippingweek != null">{{ Ncr__shippingweek }}</span>
                </v-col>
            </v-row>
            <v-row no-gutters class="pt-1">
                <v-col cols="6" class="offset-6 d-flex flex-column py-0">
                    <span class="font-weight-bold text-right">{{ $t('message.countryOriginDestination') }}</span>
                    <span class="text-right">
                        {{ Ncr__productorigin_id != null ? (Origin__alt_name != null ? Origin__alt_name : Origin__name) : '' }} /
                        {{ Ncr__destination_id != null ? Destination__name : '' }}
                    </span>
                    <span class="text-right" v-if="false">
                        {{ Loadingport__country }} /
                        {{ Ncr__destination_id != null ? Destination__name : '' }}
                    </span>
                    <span class="text-right" v-if="false">
                        {{ Origin__alt_name != null ? Origin__alt_name : Origin__name }} /
                        {{ Ncr__destination_id != null ? Destination__name : '' }}
                    </span>
                    <span class="font-weight-bold text-right">{{ $t('message.termsOfDelivery') }}</span>
                    <span class="text-right">{{ ncrIncoterm }}</span>
                </v-col>
            </v-row>
        </v-col>
        <v-col class="px-0 pb-0 pt-3 ma-0 mb-auto">
            <v-simple-table
                class="specification-table pt-3"
                dense
            >
                <template v-slot:default>
                    <tr class="table-header">
                        <td class="pl-0 pr-2 font-weight-bold border-bottom-1" v-if="false">{{ $t('message.certification') }}</td>
                        <td class="px-0 font-weight-bold border-bottom-1" v-if="false">{{ $t('message.species') }}</td>
                        <td class="px-0 font-weight-bold border-bottom-1">{{ $t('message.specification') }}</td>
                        <td class="px-0 font-weight-bold border-bottom-1">{{ $t('message.size') }}</td>
                        <td class="px-0 font-weight-bold border-bottom-1">{{ $t('message.grade') }}</td>
                        <td class="px-0 font-weight-bold text-right width-10-pct border-bottom-1">{{ $t('message.quantity') }}</td>
                        <td class="px-0 font-weight-bold text-right width-10-pct border-bottom-1">{{ $t('message.unitPrice') }}</td>
                        <td class="px-0 font-weight-bold text-right width-10-pct border-bottom-1">{{ $t('message.amount') }}</td>
                    </tr>
                    <template v-for="(item,index) in NcrItems">
                        <tr class="table-row-main" v-if="index == 0">
                            <td colspan="6">{{ getUiDescription(item) }}</td>
                        </tr>
                        <tr class="table-row" v-else-if="index > 0 && getUiDescription(NcrItems[index - 1]) != getUiDescription(item)">
                            <td colspan="6">{{ getUiDescription(item) }}</td>
                        </tr>
<!--                        <tr class="table-row-sub">-->
<!--                            <td class="px-0" v-if="false"><span class="text-no-wrap">{{ getCertification(item.NcrItem.certification_id) }}</span></td>-->
<!--                            <td class="px-0" v-if="false">{{ item.Species.title }}</td>-->
<!--                            <td class="px-0" v-if="false">{{ (item.ProductGroup.title != null ? item.ProductGroup.title + ', ' : '') + (item.NcrItem.description != null ? item.NcrItem.description : '') }}</td>-->
<!--                            <td class="px-0">{{ getUiDescription(item) }}</td>-->
<!--                            <td class="px-0">{{ item.NcrItem.size_text }}</td>-->
<!--                            <td class="px-0">{{ item.Grade.title }}</td>-->
<!--                            <td class="px-0 text-right text-no-wrap">{{ formatThisNumber(item.NcrItem.itemqty,uofmPrecision(item.Measurement.title)) + ' ' + uofmQty.find(uofm => uofm.Measurement.id == item.NcrItem.measurement_id).Measurement.title }}</td>-->
<!--                            <td class="px-0 text-right">{{ formatThisNumber(item.NcrItem.unitprice,'0,0.00') }}</td>-->
<!--                            <td class="px-0 text-right">{{ formatThisNumber(item.NcrItem.amount,currencyFormatPrecision) }}</td>-->
<!--                        </tr>-->
                        <tr>
                            <td></td>
                            <td class="px-0">{{ item.NcrItem.size_text }}</td>
                            <td class="px-0">{{ item.Grade.title }}</td>
                            <td class="px-0 text-right text-no-wrap">{{ formatThisNumber(item.NcrItem.itemqty,uofmPrecision(item.Measurement.title)) + ' ' + uofmQty.find(uofm => uofm.Measurement.id == item.NcrItem.measurement_id).Measurement.title }}</td>
                            <td class="px-0 text-right">{{ formatThisNumber(item.NcrItem.unitprice,'0,0.00') }}</td>
                            <td class="px-0 text-right">{{ formatThisNumber(item.NcrItem.amount,currencyFormatPrecision) }}</td>
                        </tr>
                    </template>
                </template>
            </v-simple-table>
        </v-col>
        <div class="px-0 pb-0 pt-3 ma-0 mt-auto">
            <v-simple-table
                class="specification-table"
                dense
            >
                <template v-slot:default>
                    <tr>
                        <td colspan="6" class="px-0">
                            <div style="white-space: pre-line" v-if="Ncr__remarks != null">
                                <div v-if="Ncr__remarks.indexOf('Remark') == -1">{{ $t('message.remarks') }}</div>
                                <div>{{ Ncr__remarks.trim() }}</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="px-0 font-weight-bold border-bottom-1">
                            <div class="d-flex flex-column" v-if="[2,3,4,5,6,9,10].includes(Ncr__fsc)">
                                <span>{{ $t('message.certCode') + ' : ' + getCertification(Ncr__fsc,'cert_no') }}</span>
                                <span>{{ $t('message.expiryDate') + ' : ' + getCertification(Ncr__fsc,'cert_expiry_date') }}</span>
                            </div>
                        </td>
                        <td class="px-0 font-weight-bold width-15-pct border-bottom-1">
                            <span class="text-uppercase pr-3">{{ $t('message.total') }}</span>
                            <span v-if=" [1,2,3,5].includes(Ncr__containersize_id) ">{{ Ncr__containercount }}</span>
                            <span v-if=" [1,2,3,5].includes(Ncr__containersize_id) "> X </span>
                            <span>{{ containerSizes.find(containerSize => containerSize.Containersize.id == Ncr__containersize_id).Containersize.title }}</span>
                        </td>
                        <td class="px-0 font-weight-bold text-right width-12-pct border-bottom-1">
                            <span v-for="(total,index) in NcrItems__totalQty">
                                {{ formatThisNumber(total.Total.qty, uofmPrecision(total.Total.uofm)) + ' '+ total.Total.uofm }}
                            </span>
                        </td>
                        <td class="px-0 font-weight-bold text-right width-10-pct border-bottom-1">{{ currencies.find((currency) => currency.Currency.id == Ncr__currency_id).Currency.code }}</td>
                        <td class="px-0 font-weight-bold text-right width-10-pct border-bottom-1">{{ formatThisNumber(NcrItems__totalAmount,currencyFormatPrecision) }}</td>
                    </tr>
                    <tr v-if="false">
                        <td colspan="4" class="px-0">
                            <div class="pt-3" style="white-space: pre-line" v-if="Ncr__remarks != null">
                                <div class="font-weight-bold">{{ $t('message.remarks') + ':' }}</div>
                                <div class="pl-3">{{ Ncr__remarks.trim() }}</div>
                            </div>
                            <div class="pt-3" style="white-space: pre-line" v-if="Ncr__internal_remarks != null">
                                <div class="font-weight-bold">{{ $t('message.remarksInternal') + ':' }}</div>
                                <div class="pl-3">{{ Ncr__internal_remarks.trim() }}</div>
                            </div>
                            <div class="pt-3" style="white-space: pre-line" v-if="Ncr__grade_remarks != null">
                                <div class="font-weight-bold">{{ $t('message.gradeRemarks') + ':' }}</div>
                                <div class="pl-3">{{ Ncr__grade_remarks.trim() }}</div>
                            </div>
                            <div class="pt-3" style="white-space: pre-line" v-if="Ncr__size_remarks != null">
                                <div class="font-weight-bold">{{ $t('message.sizeRemarks') + ':' }}</div>
                                <div class="pl-3">{{ Ncr__size_remarks.trim() }}</div>
                            </div>
                        </td>
                    </tr>
                </template>
            </v-simple-table>
            <v-row no-gutters class="pt-2">
                <v-col cols="12">{{ $t('message.notes') + ':' }}</v-col>
                <v-col cols="12" v-for="footerNote in contractFooterNotes" v-if="Contract__footer_notes.includes(footerNote.id)" :key="footerNote.id">
                    <div class="d-flex flex-row align-stretch">
                        <span class="px-1">-</span>
                        <span class="footer-note text-wrap" v-html="footerNote.text"></span>
                    </div>
                </v-col>
                <v-col cols="12" v-else-if="Ncr__destination_id == 1 && footerNote.id == 10">
                    <div class="d-flex flex-row align-stretch">
                        <span class="px-1">-</span>
                        <span class="footer-note text-wrap" v-html="footerNote.text"></span>
                    </div>
                </v-col>
            </v-row>
            <v-row no-gutters class="align-end">
                <v-col cols="7" class="d-flex flex-column py-0">
                    <span class="font-weight-bold">{{ $t('message.confirmedBySeller') }}</span>
                    <span class="stamp-area text-center pt-2">
                        <img :src="('/static/img/appic/stamps/' + Office__stamp)" height="80px">
                    </span>
                    <span class="font-weight-bold border-top-1">{{ $t('message.companyStamp') }}</span>
                </v-col>
                <v-col cols="5" class="d-flex flex-column py-0">
                    <span class="font-weight-bold ml-3">{{ $t('message.confirmedByBuyer') }}</span>
                    <span class="stamp-area"></span>
                    <span class="font-weight-bold border-top-1 ml-3">{{ '(' + $t('message.pleaseSign') + ')' }}</span>
                </v-col>
            </v-row>
        </div>
        <h1 id="loaded" v-if="loaded" style="margin-bottom: 0px !important;"></h1>
    </div>
</template>

<script>
import AppConfig from "Constants/AppConfig";
import {formatDate, isNumeric, log, nl2br, numberFormat} from "Helpers/helpers";
import {api} from "Api";
import {
    // buyerCaptionTypes,
    // certifications,
    // certificationsNew,
    containerSizes,
    contractFooterNotes,
    // currencies,
    // deliveryAddressTypes,
    incoterms,
    offices,
    productGroupsNew,
    uofm
} from "../../../store/modules/appic/constants"
// import roundTo from "round-to";

export default {
    name: "PrintNcrNew",
    props: ['ncrId'],
    data() {
        return {
            appLogo: AppConfig.appSessionLogo,
            loaded: false,
            certificationsNew: [],
            // certificationsNew: certificationsNew,
            containerSizes: containerSizes,
            contractFooterNotes: contractFooterNotes,
            currencies: [],
            // currencies: currencies,
            incoterms: incoterms,
            offices: offices,
            productGroupsNew: productGroupsNew,
            uofm: uofm,
            uofmQty: uofm.qty,
            Customer__title: null,
            Customer__address1: null,
            Customer__address2: null,
            Customer__address3: null,
            Customer__city: null,
            Customer__gstno: null,
            Customer__postcode: null,
            Customer__state: null,
            Customer__country: null,
            Customer__country_id: 1,
            Customer__uscc: null,
            Customer__taxno_type: null,
            Deliveryaddress__address: null,
            Destination__name: null,
            Loadingport__country: null,
            Loadingport__title: null,
            Ncr__containercount: null,
            Ncr__containersize_id: 1,
            Ncr__currency_id: 1,
            Ncr__destination_id: 1,
            Ncr__grade_remarks: null,
            Ncr__id: null,
            Ncr__incoterm_id: 1,
            Ncr__incotermport: null,
            Ncr__internal_remarks: null,
            Ncr__etddate: null,
            Ncr__loadingport_id: null,
            Ncr__ncrdate: null,
            Ncr__productorigin_id: null,
            Ncr__remarks: null,
            Ncr__salestype_id: null,
            Ncr__shippingport_id: null,
            Ncr__shippingweek: null,
            Ncr__size_remarks: null,
            Ncr__title: null,
            NcrItems: [],
            NcrItems__totalAmount: 0,
            NcrItems__totalCost: 0,
            NcrItems__totalQty: [],
            Paymentstatement__title: null,
            Office__title: null,
            Office__address1: null,
            Office__address2: null,
            Office__city: null,
            Office__companyno: null,
            Office__contractemail: null,
            Office__contracttel: null,
            Office__country: null,
            Office__postcode: null,
            Office__state: null,
            Office__stamp: 'stampappsdnbhd.png',
            Office__stamp_ws: 'stampsdnbhd.png',
            Office__website: null,
            Origin__alt_name: null,
            Origin__name: null,
            Paymentterm__title: null,
            Salescontact__name: null,
            ShippingPort__title: null,
            Supplier__title: null,
            Supplier__address1: null,
            Supplier__address2: null,
            Supplier__address3: null,
            Supplier__city: null,
            Supplier__postcode: null,
            Supplier__state: null,
            Supplier__country: null
        }
    },
    computed: {
        buyerCaption() {

        },
        Contract__footer_notes() {
            return [1,2,3,4,5,6,7,8,9]
        },
        currencyFormatPrecision () {
            return this.currencies.find((currency) => currency.Currency.id === this.Ncr__currency_id)?.Currency?.formatPrecision
        },
        currencyPrecision () {
            return this.currencies.find((currency) => currency.Currency.id === this.Ncr__currency_id)?.Currency?.decimals
        },
        deliveryCaption() {

        },
        ncrIncoterm() {
            let incoterm = this.incoterms.find((incoterm)=>incoterm.Incoterm.id == this.Ncr__incoterm_id)?.Incoterm?.title;
            let incotermport = '';
            switch(this.Ncr__incoterm_id){
                case null:
                    incotermport = ''
                    break;
                case 1:
                case 4:
                case 6:
                    if(this.Ncr__loadingport_id != null) {
                        incotermport = ' ' + this.Loadingport__title
                    }
                    break
                case 2:
                case 3:
                case 7:
                    if(this.Ncr__shippingport_id != null) {
                        incotermport = ' ' + this.ShippingPort__title
                    }
                    break;
                default:
                    incotermport = ''
            }
            return incoterm + incotermport;
        },
        Ncr__fsc: {
            get: function () {
                let fscId = null
                if (this.NcrItems.length > 0) {
                    this.NcrItems.forEach(item => {
                        if (item.NcrItem.certification_id != null && [0, 7, 8].includes(item.NcrItem.certification_id) == false) {
                            fscId = item.NcrItem.certification_id
                        }
                    })
                }
                return fscId
            },
            set: function (newValue) {
                //do nothing
            }
        }
    },
    methods: {
        formatDate,
        formatThisNumber(value, format) {
            return numberFormat(value, format)
        },
        // getCertification(certificationId) {
        //     let certificationObj = this.certificationsNew.find(c => c.Certification.id == certificationId)
        //     if(certificationObj){
        //         return certificationObj.Certification.title
        //     }
        //     return ''
        // },
        getCertification(certificationId, certificationInfo) {
            let certificationObj = this.certificationsNew.find(c => c.Certification.id == certificationId)
            if(certificationObj){
                return certificationObj?.Certification[certificationInfo]
            }
            return ''
        },
        getCertifications() {
            return new Promise((resolve, reject) => {
                api
                    .get("/print/certifications",{
                    })
                    .then(response => {
                        resolve(response.data.data)
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        getCurrencies() {
            return new Promise((resolve, reject) => {
                log('get currencies')
                api
                    .get("/print/currencies",{
                    })
                    .then(response => {
                        resolve(response.data.data)
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        getTaxId () {
            let taxId = ''
            if(this.Customer__gstno != null && this.Customer__taxno_type != null) taxId = this.Customer__taxno_type + ': ' + this.Customer__gstno
            if(this.Customer__gstno != null && this.Customer__taxno_type == null) taxId = this.Customer__gstno
            return taxId
        },
        getUiDescription(item) {
            let uiDescription = []

            // let species = this.allSpecies.find(s => s.Species.id == item.NcrItem.species_id)
            if(item.Species.title != '') uiDescription.push(item.Species.title)

            if(item.NcrItem.description != null) {
                if (item.ProductGroup.title != '' && item.NcrItem.description.includes(item.ProductGroup.title) == false) uiDescription.push(item.ProductGroup.title)
            } else {
                if (item.ProductGroup.title != null) uiDescription.push(item.ProductGroup.title)
            }

            if(item.NcrItem.description != '') uiDescription.push(item.NcrItem.description)

            if(item.NcrItem.description != null) {
                if (item.Certification.title != '' && item.NcrItem.description.includes(item.Certification.title) == false && item.Certification.title != 'None') uiDescription.push(item.Certification.title)
            } else {
                if (item.Certification.title != null) uiDescription.push(item.Certification.title)
            }

            // let certification = this.allCertifications.find(c => c.Certification.id == item.NcrItem.certification_id)
            // if(certification && item.NcrItem.description != null && item.NcrItem.description.includes(certification.Certification.title) == false && certification.Certification.title != 'None') uiDescription.push(certification.Certification.title)
            // if(certification && item.NcrItem.description == null && certification.Certification.title != 'None') uiDescription.push(certification.Certification.title)

            uiDescription = uiDescription.join(', ')
            return uiDescription
        },
        loadNcrById(ncrId) {
            let self = this
            return new Promise((resolve, reject) => {
                api
                    .get("/print/ncrs/" + ncrId)
                    .then(response => {
                        for(let key in response.data.data[0]){
                            self[key] = response.data.data[0][key]
                        }
                        resolve(ncrId)
                    })
                    .catch(error => {
                        reject(error);
                    })
                    .finally(()=>{
                        resolve(ncrId)
                    })
            })
        },
        loadItemsByNcr(ncrId) {
            return new Promise((resolve, reject) => {
                api
                    .get("/print/ncrs/" + ncrId + '/items',{
                    })
                    .then(response => {
                        this.NcrItems = response.data.data
                        resolve(ncrId)
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        nl2br,
        async updateTotals () {
            let amount = 0, cost = 0, quantities = []
            await this.NcrItems.forEach((item) => {
                cost += isNumeric(item.NcrItem.cost) ? parseFloat(item.NcrItem.cost) : 0;
                amount += isNumeric(item.NcrItem.amount) ? parseFloat(item.NcrItem.amount) : 0;

                let idx = quantities.findIndex(t=>t.Total.uofm == item.Measurement.title);
                if(idx !== -1){
                    quantities[idx]['Total']['qty'] += isNumeric(item.NcrItem.itemqty) ? parseFloat(item.NcrItem.itemqty) : 0;
                } else {
                    let obj = {'Total': {}}
                    obj['Total']['uofm'] = item.Measurement.title;
                    obj['Total']['qty'] = isNumeric(item.NcrItem.itemqty) ? parseFloat(item.NcrItem.itemqty) : 0;
                    quantities.push(obj)
                }
            })
            this.NcrItems__totalAmount = amount
            this.NcrItems__totalCost = cost
            this.NcrItems__totalQty = quantities
        },
        uofmPrecision (uofm) {
            return this.uofmQty.find((u) => u.Measurement.title == uofm.trim())?.Measurement?.formatPrecision
        },
    },
    created() {
        this.getCertifications()
            .then((certifications) => {
                this.certificationsNew = certifications
                this.getCurrencies()
                    .then((currencies) => {
                        this.currencies = currencies
                    })
                    .catch()
            })
            .catch()
        if(this.$route.matched.some(({name}) => name === 'print_ncr')){
            this.loadNcrById(this.ncrId)
                .then(() => {
                    this.loadItemsByNcr(this.ncrId)
                        .then(() => {
                            this.updateTotals()
                            this.loaded = true
                        })
                })
        }
    }
}
</script>

<style>
    @font-face {
        font-family: 'AppicArial';
        src: url('/static/fonts/Arial.woff2') format('woff2'),
        url('/static/fonts/Arial.woff2') format('woff'),
        url('/static/fonts/Arial.woff2') format('truetype');
    }
    .border-top-1 {
        border-top: 1px solid black !important;
    }
    .border-bottom-1 {
        border-bottom: 1px solid black !important;
    }
    .border-top-2 {
        border-top: 2px solid black;
    }
    .border-bottom-2 {
        border-bottom: 2px solid black !important;
    }
    .col-2-5 {
        flex: 0 0 20.83333333%;
        max-width: 20.83333333%;
    }
    .col-9-5 {
        flex: 0 0 79.16666667%;
        max-width: 79.16666667%;
    }
    .document-caption {
        font-size: 16px !important;
        line-height: 18px;
    }
    .footer-note {
        font-size: 8pt !important;
    }
    .header-title {
        font-size: 20pt !important;
        line-height: 25px;
        font-weight: bold !important;
    }
    .header-subtitle {
        font-size: 10.5pt !important;
    }
    .specification-table table tr td {
        font-family:'AppicArial' !important;
        line-height:15px !important;
        font-size:9pt !important;
        padding:2px !important;
    }
    .specification-table table tr.table-header td {
        font-family:'AppicArial' !important;
        line-height:15px !important;
        font-size:9pt !important;
        padding:0px !important;
        height: 15px !important;
    }
    .specification-table table tr.table-row-main td {
        font-family:'AppicArial' !important;
        line-height:20px !important;
        font-size:9pt !important;
        padding:0px !important;
        height: 20px !important;
    }
    .specification-table table tr.table-row-sub td {
        font-family:'AppicArial' !important;
        line-height:15px !important;
        font-size:9pt !important;
        padding:0px !important;
        height: 15px !important;
    }
    .stamp-area {
        height: 100px;
    }
    .v-application{
        font-family:'AppicArial' !important;
        line-height:15px !important;
        font-size:9pt !important;
        padding:2px !important;
    }
    .v-application{
        font-family: 'AppicArial' !important;
        line-height:15px !important;
        font-size:9pt !important;
        padding:2px !important;
    }
    body {
        width: 18.5cm !important;
        margin: 0 !important;
    }
    * {
        text-rendering: geometricprecision !important;
    }
</style>